import { createRouter, createWebHistory } from 'vue-router'

// layouts
const DefaultLayout = () => import('../layouts/DefaultLayout.vue')

// views
const HomeView = () => import('../views/HomeView.vue')
const GovernanceView = () => import('../views/Benin/GovernanceView.vue')
const BusinessClimateView = () => import('../views/Benin/BusinessClimateView.vue')
const AboutBeninView = () => import('../views/Benin/AboutBeninView.vue')
const GDIZView = () => import('../views/Business/GDIZView.vue')
const OtherIndustriesView = () => import('../views/Business/OtherIndustriesView.vue')
const ContactView = () => import('../views/ContactView.vue')
const ConstructionView = () => import('../views/Construction/ConstructionView.vue')

const routes = [
  // Errors

  // Views
  /*{
    path: '/',
    component: ConstructionView
  },*/
  {
    path: '',
    redirect: '/',
    meta: { isAuth: false, neededRole: [] },
    component: DefaultLayout,
    children: [
      // Home
      {
        path: '',
        name: 'home',
        component: HomeView,
      },

      // Benin
      {
        path: 'benin',
        name: 'benin',
        children: [
          {
            path: 'governance',
            name: 'governance',
            component: GovernanceView
          },
          {
            path: 'about-benin',
            name: 'about-benin',
            component: AboutBeninView
          },
          {
            path: 'business-climate',
            name: 'business-climate',
            component: BusinessClimateView
          }
        ]
      },

      // Business
      {
        path: 'business',
        name: 'business',
        children: [
          {
            path: 'gdiz',
            name: 'gdiz',
            component: GDIZView
          },
          {
            path: 'other-industries',
            name: 'other industries',
            component: OtherIndustriesView
          }
        ]
      },

      // Contact
      {
        path: 'contact',
        name: 'contact',
        component: ContactView
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        const targetElement = document.querySelector(to.hash);
        if (targetElement) {
          const rect = targetElement.getBoundingClientRect();
          const offsetY = window.pageYOffset || document.documentElement.scrollTop;
          const targetTop = rect.top + offsetY;

          resolve({ top: targetTop, behavior: 'smooth' });
        } else {
          reject({ behavior: 'smooth' });
        }
      });
    } else if (savedPosition) {
      // Si une position précédemment sauvegardée existe, utilisez-la
      return savedPosition;
    } else {
      // Sinon, faites défiler vers le haut de la page
      return { top: 0 };
    }
  },
})

export default router
