<template>
  <router-view/>
</template>

<style lang="scss">
 #app {
   min-height: 100vh;
   display: flex;
   flex-flow: column;

   * {
     font-family: 'Lato', sans-serif;
   }

   h1,h2,h3,h4,h5,h6 {
     font-family: "Lato", sans-serif;
   }

   nav {
     a {
       border-bottom: transparent 3px solid;
       padding: 0 !important;
       // font-weight: 300 !important;

       &.router-link-exact-active {
         border-bottom: #D35A46 3px solid;
       }
     }
   }

   .form-control, .form-select {
     border-color: #1a5e9d;
     font-size: 14px;
     border-radius: 0;

     &:focus {
       border-color: #1a5e9d;
       box-shadow: 0 0 0 0.25rem rgb(26 94 157 / 25%);
     }
   }

   .btn {
     width: fit-content;
     color: #FFFFFF;
     padding: .5rem 1rem;
     font-family: 'Lato', sans-serif !important;
     border-radius: .5rem;
   }

   h1.rubric-title {
     position: relative;
     font-family: 'Lato', sans-serif;
     font-weight: 400;
     color: #061E4E;
   }

   h1.rubric-title::before {
     content: "";
     position: absolute;
     top: -15px; /* Ajustez la valeur pour positionner la barre par rapport au texte */
     left: 0;
     width: 70px; /* La moitié de la longueur du texte */
     height: 3px; /* Hauteur de la barre */
     background-color: #D35A46; /* Couleur de la barre */
   }

   p.rubric-paragraph {
     font-family: 'Lato', sans-serif;
     font-weight: 400;
     font-size: 18px;
     margin: 1rem auto 1rem 0;
     text-align: justify;
   }

   .background-one {
     background-color: rgba(47, 46, 46, 0.05);
   }

   .background-two {
     background-color: rgba(31, 100, 170, 0.07);
   }

   .background-three {
     background-color: #061E4E;
     color: #FFFFFF;

     h1 {
       color: #FFFFFF;
     }
   }

   .background-black {
     background-color: rgba(47, 46, 46, 0.05);
   }

   img {
     object-fit: cover;
   }
 }
</style>
<script setup>
</script>
