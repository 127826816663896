import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/*------------------------------------- Bootstrap & Bootstrap Icons -------------------------------------*/
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import '../custom.scss';
import 'bootstrap-icons/font/bootstrap-icons.scss'

/*------------------------------------- I18n -------------------------------------*/
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  // something vue-i18n options here ...
  locale: 'dk',
  messages: {
    fr: require('./locales/fr.json'),
    en: require('./locales/en.json'),
    dk: require('./locales/dk.json')
  }
})

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .mount('#app')
